import React from "react"
import { Navbar, NavbarBrand } from 'reactstrap'

class BatList extends React.Component {


  render() {
    const { bats } = this.props    

    let batLinks = bats.sort((a, b) =>  {
        if (a.manufacturer + " " + a.series > b.manufacturer + " " + b.series) return 1;
        else if (a.manufacturer + " " + a.series < b.manufacturer + " " + b.series) return -1;
        else return 0; 
      }).map((bat) => {
      return <div key={bat.slug}><a href={`/${bat.slug}`}>{bat.year} {bat.manufacturer} {bat.series}</a></div>
    })


    return (

            <div>
                <Navbar color="dark" dark>
                  <NavbarBrand className="mr-auto" style={{ color: "white" }}>All Bat Reviews</NavbarBrand>
                </Navbar>
                <div style={{ backgroundColor: "white", padding: "10px" }}>
                  {batLinks}
                </div>
            </div>



    )
  }


}

export default BatList
