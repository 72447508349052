import React from "react"
import Layout from "../../components/layout"
import { graphql } from 'gatsby'
import { Container, Row, Col, Navbar, NavbarBrand, FormGroup, Form, Input, Table } from 'reactstrap'
import Helmet from 'react-helmet'
import BatList from "../../components/batlist/batlist";

class BatPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { leftBatIndex: 0, rightBatIndex: 1 };
  }

  render() {

    const data = this.props.data
    const bats = data.allBat.nodes;

    bats.sort((a, b) => {
      if (a.manufacturer + ' ' + a.series > b.manufacturer + ' ' + b.series) return 1
      else if (a.manufacturer + ' ' + a.series < b.manufacturer + ' ' + b.series) return -1
      else return 0;
    })

    let batOptions = bats.map((bat, index) => {
      return <option value={index}>{bat.year} {bat.manufacturer} {bat.series}</option>
    })

    let leftBat = bats[this.state.leftBatIndex];
    let rightBat = bats[this.state.rightBatIndex];

    return (
      <Layout>

        <Helmet
          title="Compare Fastptich Softball Bats"
          meta={[
            { name: 'description', content: "Compare the details for the most popular fastpitch softball bats" },
          ]}
        />

        <div className="wrapper">

          <Container>
            <Row>
              <Col>
                <div style={{ backgroundColor: "white", padding: "10px", marginBottom: "25px", borderTop: "4px solid #343a40" }}>
                  Choosing the right bat is a very personal decision.  Since top-tier bats can be a large investment, we recommend you find a vendor that will let you try out bats before you buy.  We have compiled as much information as possible to make your decision easier.
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={8} md={12}>
                <Navbar color="dark" dark>
                  <NavbarBrand className="mr-auto" style={{ color: "white" }}>Compare Bats</NavbarBrand>
                </Navbar>

                <div style={{ backgroundColor: "white", padding: "10px" }}>
                  Choose the bats you want to compare or read one of our full reviews
                </div>


                <div style={{ backgroundColor: "white", padding: "10px", marginBottom: "25px" }}>


                  <Form>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Input type="select" onChange={(e) => this.setState({ leftBatIndex: e.target.value })} value={this.state.leftBatIndex}>
                            <option value="">Choose Bat To Compare</option>
                            {batOptions}
                          </Input>
                        </FormGroup>

                        {leftBat &&
                          <div style={{ textAlign: "center" }}>


                            <img src={leftBat.images[0]} alt={leftBat.series} style={{ height: "150px", width: "auto", margin: "5px" }} />
                            <Table striped={true}>

                              <tbody>
                                <tr>
                                  <th scope="row">MSRP</th>
                                  <td>${leftBat.msrp}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Material</th>
                                  <td>{leftBat.material}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Drops Available</th>
                                  <td>{leftBat.drops.join(', ')}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Sizes Available</th>
                                  <td>{leftBat.sizes.join(', ')}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Certifications</th>
                                  <td>{leftBat.certifications.join(', ')}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Rating</th>
                                  <td>{leftBat.rating}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Warranty</th>
                                  <td>{leftBat.warranty}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Manufacturer Website</th>
                                  <td><a href={leftBat.link} target="_blank" rel="nofollow noopener noreferrer">{leftBat.manufacturer}</a></td>
                                </tr>
                              </tbody>
                            </Table>
                            <a href={`/${leftBat.slug}`}>Read {leftBat.year} {leftBat.manufacturer} {leftBat.series} Review</a>
                          </div>
                        }

                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Input type="select" onChange={(e) => this.setState({ rightBatIndex: e.target.value })} value={this.state.rightBatIndex}>
                            <option>Choose Bat To Compare</option>
                            {batOptions}
                          </Input>
                        </FormGroup>

                        {rightBat &&
                          <div style={{ textAlign: "center" }}>


                            <img src={rightBat.images[0]} alt={rightBat.series} style={{ height: "150px", width: "auto", margin: "5px" }} />

                            <Table striped={true}>

                              <tbody>
                                <tr>
                                  <th scope="row">MSRP</th>
                                  <td>${rightBat.msrp}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Material</th>
                                  <td>{rightBat.material}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Drops Available</th>
                                  <td>{rightBat.drops.join(', ')}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Sizes Available</th>
                                  <td>{rightBat.sizes.join(', ')}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Certifications</th>
                                  <td>{rightBat.certifications.join(', ')}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Rating</th>
                                  <td>{rightBat.rating}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Warranty</th>
                                  <td>{rightBat.warranty}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Manufacturer Website</th>
                                  <td><a href={rightBat.link} target="_blank" rel="nofollow noopener noreferrer">{rightBat.manufacturer}</a></td>
                                </tr>
                              </tbody>
                            </Table>
                            <a href={`/${rightBat.slug}`}>Read {rightBat.year} {rightBat.manufacturer} {rightBat.series} Review</a>
                          </div>
                        }

                      </Col>
                    </Row>
                  </Form>




                </div>
              </Col>
              <Col lg={4} md={12}>
                <BatList bats={bats} />
              </Col>



            </Row>

          </Container>
        </div>
      </Layout>
    )
  }


}

export default BatPage

export const query = graphql`
  {  
    allBat {
        nodes {
          manufacturer
          series
          year
          description
          images     
          slug     
          headline
          material
          diameter
          drops
          sizes
          msrp
          rating
          link
          certifications
          warranty
          features {
            headline
            detail
          }
        }                  
      }
  }
`